/* unplugin-vue-components disabled */import { useEmptyStringFormat } from '@/hooks/fn';
export default defineComponent({
  name: 'BudgetItem',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  setup: function setup() {
    var emptyStringFormat = useEmptyStringFormat();
    return {
      emptyStringFormat: emptyStringFormat
    };
  }
});