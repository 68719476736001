import hyRequest from '@/service';
import {
  ITotalData,
  ITotalParams,
  ITrendParams,
  ITrendDataItem,
  IElectricPriceItem,
  // IElectricBasicPrice,
  IElectricPriceParams
} from './type';

enum DashboardApi {
  getElectricMeterStatistics = '/electricMeter/electricMeterStatistics',
  getElectricMeterTrend = '/electricMeter/electricMeterTrend',
  getElectricPriceRule = '/electricMeter/electricPriceRule',
  getElectricBasicsPrice = '/electricMeter/electricBasicsPrice'
}

// 耗电量统计及占比
export function getElectricMeterStatistics(params: ITotalParams) {
  return hyRequest.post<ITotalData>({
    url: DashboardApi.getElectricMeterStatistics,
    data: params
  });
}
//耗电量趋势图
export function getElectricMeterTrend(params: ITrendParams) {
  return hyRequest.post<{ list: ITrendDataItem[] }>({
    url: DashboardApi.getElectricMeterTrend,
    data: params
  });
}
export function getElectricPriceRule(params: IElectricPriceParams) {
  return hyRequest.post<{ list: IElectricPriceItem[] }>({
    url: DashboardApi.getElectricPriceRule,
    data: params
  });
}
export function getElectricBasicsPrice() {
  return hyRequest.post<{ list: IElectricPriceItem[] }>({
    url: DashboardApi.getElectricBasicsPrice
  });
}
