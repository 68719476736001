/* unplugin-vue-components disabled */import _slicedToArray from "D:/code/org/guanjia/blueSkyReportForm/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import { useEmptyStringFormat } from '@/hooks/fn';
import useCostBudget from './hooks/useCostBudget';
import BudgetItem from '@/components/BudgetItem.vue';
import useBudgetSurvey from './hooks/useBudgetSurvey';
import useProjectBudget from './hooks/useProjectBudget';
import useLogisticsBudget from './hooks/useLogisticsBudget';
import useSaleAdvanceReceipt from './hooks/useSaleAdvanceReceipt';
import { BarEchartMul, PieEchart } from '@/components/page-echarts';
export default defineComponent({
  name: 'BudgetReport',
  components: {
    BarEchartMul: BarEchartMul,
    PieEchart: PieEchart,
    BudgetItem: BudgetItem
  },
  setup: function setup() {
    var emptyStringFormat = useEmptyStringFormat();
    var params1 = ref({
      month: dayjs().format('YYYY-MM')
    });
    //获取预算概况数据
    var _useBudgetSurvey = useBudgetSurvey(params1),
      _useBudgetSurvey2 = _slicedToArray(_useBudgetSurvey, 2),
      budgetSurvey = _useBudgetSurvey2[0],
      getBudgetSurveyAsync = _useBudgetSurvey2[1];
    //获取费用预算数据
    var _useCostBudget = useCostBudget(params1),
      _useCostBudget2 = _slicedToArray(_useCostBudget, 3),
      costBudgetTotal = _useCostBudget2[0],
      costBudgetData = _useCostBudget2[1],
      getCostBudgetAsync = _useCostBudget2[2];
    //获取专项报表数据
    var _useProjectBudget = useProjectBudget(params1),
      _useProjectBudget2 = _slicedToArray(_useProjectBudget, 3),
      projectBudgetTotal = _useProjectBudget2[0],
      projectBudgetData = _useProjectBudget2[1],
      getProjectBudgetAsync = _useProjectBudget2[2];
    //获取物流预算
    var _useLogisticsBudget = useLogisticsBudget(params1),
      _useLogisticsBudget2 = _slicedToArray(_useLogisticsBudget, 3),
      logisticsBudgetTotal = _useLogisticsBudget2[0],
      logisticsBudgetData = _useLogisticsBudget2[1],
      getLogisticsBudgetAsync = _useLogisticsBudget2[2];
    //获取销售预收
    var _useSaleAdvanceReceip = useSaleAdvanceReceipt(params1),
      _useSaleAdvanceReceip2 = _slicedToArray(_useSaleAdvanceReceip, 3),
      saleAdvanceReceiptTotal = _useSaleAdvanceReceip2[0],
      saleAdvanceReceiptData = _useSaleAdvanceReceip2[1],
      getSaleAdvanceReceiptAsync = _useSaleAdvanceReceip2[2];
    onMounted(function () {
      getBudgetSurveyAsync();
      getCostBudgetAsync();
      getProjectBudgetAsync();
      getLogisticsBudgetAsync();
      getSaleAdvanceReceiptAsync();
    });
    return {
      emptyStringFormat: emptyStringFormat,
      budgetSurvey: budgetSurvey,
      costBudgetTotal: costBudgetTotal,
      costBudgetData: costBudgetData,
      projectBudgetTotal: projectBudgetTotal,
      projectBudgetData: projectBudgetData,
      logisticsBudgetTotal: logisticsBudgetTotal,
      logisticsBudgetData: logisticsBudgetData,
      saleAdvanceReceiptTotal: saleAdvanceReceiptTotal,
      saleAdvanceReceiptData: saleAdvanceReceiptData
    };
  }
});