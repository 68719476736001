export interface ITotal {
  daySalesMoney: number;
  daySalesValue: number;
  monthSalesMoney: number;
  monthSalesValue: number;
}
export interface IListItem {
  dayMoney: string;
  dayValue: string;
  monthMoney: number;
  monthValue: number;
  stoneName: string;
}
export interface ISales {
  concreteList: IListItem[];
  concreteTotal: ITotal;
  daySalesMoney: number;
  daySalesValue: number;
  monthSalesMoney: number;
  monthSalesValue: number;
  mortarList: IListItem[];
  mortarTotal: ITotal;
  stoneList: IListItem[];
  stoneTotal: ITotal;
}
export interface IProTotal {
  daySalesValue: number;
  monthSalesValue: number;
}
export interface IProListItem {
  dayValue: number;
  monthValue: number;
  stoneName: string;
}
export interface IProduction {
  concreteList: IProListItem[];
  concreteTotal: IProTotal;
  daySalesValue: number;
  monthSalesValue: number;
  mortarList: IProListItem[];
  mortarTotal: IProTotal;
  stoneList: IProListItem[];
  stoneTotal: IProTotal;
}
export interface ICount {
  monthReleaseMoney: number;
  monthReleaseNums: number;
  releaseMoney: number;
  releaseNums: number;
  releaseUnitPrice: number;
  stoneName: string;
}
export interface IReceiving {
  aggregateCount: ICount;
  aggregateList: any[];
  concreteCount: ICount;
  concreteList: any[];
  mortarCount: ICount;
  mortarList: any[];
  totalCount: ICount;
}
export interface IAccount {
  day_gl_recharge: number;
  day_sj_recharge: number;
  day_st_receivable: number;
  day_st_recharge: number;
  month_st_receivable: number;
}
export interface ILineStatusData {
  list: ILineStatusItem[];
}
export interface ILineStatusItem {
  start_time: string;
  show_time: string;
  end_time: string;
  line_name: string;
  status: string;
}
export interface ILineTimeItem {
  is_main: string;
  line_name: string;
  openCloseTime: string;
  id: number;
  status: string;
}
export interface IAttendance {
  no_sign_count: number;
  leave_count: number;
  leave_days_count: number;
  leave_early_count: number;
  require_count: number;
  absent_count: number;
  late_count: number;
  attendance_count: number;
  absent_from_work: number;
}
export interface IAttendanceParams {
  date: string;
}
export interface ISaleParams {
  date: string;
  type: string;
}
export interface ISaleData {
  totalSettleValue: number;
  totalMoneyYuan: number;
  totalAveragePrice: number;
  totalMoney: number;
  settleValueList: { name: string; value: number; ratio: string }[];
  totalSingular: number;
  totMoneyList: { name: string; value: number; ratio: string }[];
  timeRange: string;
}

export interface IPurchaseSumary {
  mortarTotalSingular: number; // 砂浆单数
  mortarTotalAveragePrice: number; // 砂浆均价（元/吨）
  aggregateTotalSingular: number; // 骨料单数
  aggregateTotalSettleValue: number; // 骨料进料量（吨）
  aggregateTotalAveragePrice: number; // 骨料均价（元/吨）
  commercialTotalSingular: number; // 商砼单数
  aggregateTotalMoney: number; // 骨料总金额（万元）
  commercialTotalMoney: number; // 商砼总金额（万元）
  commercialTotalAveragePrice: number; // 商砼单价（元/吨）
  commercialTotalSettleValue: number; // 商砼进料量（吨）
  mortarTotalMoney: number; // 砂浆总金额（万元）
  mortarTotalSettleValue: number; // 砂浆进料量（吨）
  aggregateTotalMoneyYuan: number; // 骨料总金额（元）
  commercialTotalMoneyYuan: number; // 商砼总金额（元）
  mortarTotalMoneyYuan: number; // 砂浆总金额（元）
}

export interface ISaleSummary {
  aggregateTotalMoney: number; // 骨料总金额（万元）
  aggregateTotalMoneyYuan: number; // 骨料总金额（元）
  aggregateTotalSettleValue: number; // 骨料进料量（吨）
  commercialTotalMoney: number; // 商砼总金额（万元）
  commercialTotalMoneyYuan: number; // 商砼总金额（元）
  commercialTotalSettleValue: number; // 商砼进料量（吨）
  mortarTotalMoney: number; // 砂浆总金额（万元）
  mortarTotalMoneyYuan: number; // 砂浆总金额（元）
  mortarTotalSettleValue: number; // 砂浆进料量（吨）
}

export interface IReceiveSumary {
  aggregateReleaseNums: number;
  commercialTaxMoney: number;
  mortarTaxMoney: number;
  commercialReleaseNums: number;
  mortarReleaseNums: number;
  aggregateTaxMoney: number;
}

export interface IProSumaryData {
  mortarPro: number; // 砂浆产量（吨）
  stonePro: number; // 骨料产量（吨）
  commercialPro: number; // 商砼产量（吨）
}

export interface IProSumary {
  desc: string;
  value: number;
  routeName: string;
}

export enum EPermission {
  no = '0',
  yes = '1'
}

export interface ICollectionInfo {
  factory_id?: number; // 厂区主键
  collect_date: string; // 收款日期
  update_time?: string;
  deleted?: number;
  create_time?: string;
  concrete_collect: number; // 商砼收款（元）
  stone_collect: number; // 骨料收款（元）
  id?: number;
  mortar_collect: number; // 砂浆收款（元）
}

export type TShowBlueSkyBudget = '0' | '1';
