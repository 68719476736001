import { hyRequest3 } from '@/service';
import {
  ICostBudget,
  IBudgetParams,
  IBudgetSurvey,
  IProjectBudget,
  ILogisticsBudget,
  ISaleAdvanceReceipt
} from './type';

enum DashboardApi {
  getBudgetSurvey = '/budget/budgetSurvey',
  getCostBudget = '/budget/costBudget',
  getProjectBudget = '/budget/projectBudget',
  getLogisticsBudget = '/budget/logisticsBudget',
  getSaleAdvanceReceipt = '/budget/saleAdvanceReceipt'
}

//查询月份内的预算概况
export function getBudgetSurvey(params: IBudgetParams) {
  return hyRequest3.get<IBudgetSurvey>({
    url: DashboardApi.getBudgetSurvey,
    params
  });
}
//费用预算
export function getCostBudget(params: IBudgetParams) {
  return hyRequest3.get<ICostBudget>({
    url: DashboardApi.getCostBudget,
    params
  });
}
//专项报表
export function getProjectBudget(params: IBudgetParams) {
  return hyRequest3.get<IProjectBudget>({
    url: DashboardApi.getProjectBudget,
    params
  });
}
//物流预算
export function getLogisticsBudget(params: IBudgetParams) {
  return hyRequest3.get<ILogisticsBudget>({
    url: DashboardApi.getLogisticsBudget,
    params
  });
}
//销售预收
export function getSaleAdvanceReceipt(params: IBudgetParams) {
  return hyRequest3.get<ISaleAdvanceReceipt>({
    url: DashboardApi.getSaleAdvanceReceipt,
    params
  });
}
