/* unplugin-vue-components disabled */import { getPointlistForEqu } from '@/service/operate/dailyInspection';
export default defineComponent({
  name: 'DailyInspectionDetail',
  setup: function setup() {
    var detail = ref([]);
    var route = useRoute();
    onMounted(function () {
      console.log(route.query);
      getPointlistForEqu({
        equId: route.query.id,
        date: route.query.date,
        hasPatrol: route.query.state
      }).then(function (data) {
        console.log(data.list);
        detail.value = data.list;
      });
    });
    return {
      detail: detail
    };
  }
});